import React, {useEffect} from 'react';
import Sidebar from './Sidebar';
import '../../styles/pressReleases.css';

const Report = () => {
    useEffect(() => {
        // Set the browser tab title
        document.title = "Report | LinkNYC";

        // Optional cleanup if you want to reset the title on unmount
        return () => {
            document.title = "LinkNYC";
        };
    }, []); // Empty dependency array to run only once when the component mounts

    const postList = (
        <div className='news-post-list'>
            <h3>
                <div></div>
            </h3>
            <div className='news-post' key='1'>
                <a
                    rel="noopener noreferrer"
                    href={`/impact-report`}
                >
                    <div className='news-post-title'>2024 Impact Report</div>
                </a>
                <span className='news-post-date'>March 4, 2025</span>
                <hr/>
            </div>
        </div>
    );

    return (
        <div className='top-div'>
            <section className='news-section-1'>
                <div className='news-section-div'>
                    <h1>
                        <div style={{backgroundColor: 'white'}}></div>
                        Reports
                    </h1>
                </div>
            </section>
            <section className='news-section-2'>
                <Sidebar/>
                {postList}
            </section>
        </div>
    );
}

export default Report;
