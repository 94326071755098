import React, {useEffect} from 'react';
import Sidebar from "./Sidebar";
import defaultTitleBackgroundImage from "../../assets/img/press-releases.jpg";
import PDFViewer from "./PDFViewer";

const ImpactReport = () => {
    const backgroundImage = defaultTitleBackgroundImage;

    const postContentSection1 = {
        backgroundImage: `url(${backgroundImage})`
    }

    useEffect(() => {
        // Set the browser tab title
        document.title = "Press | LinkNYC";

        // Optional cleanup if you want to reset the title on unmount
        return () => {
            document.title = "LinkNYC";
        };
    }, []); // Empty dependency array to run only once when the component mounts

    const postContent = (
        <div className='post-content' style={{width: '100%'}}>
            <h3>
                <div></div>
            </h3>
            <div className='post-content-date'>March 4, 2025</div>
            <PDFViewer pdfSrc={"/LinkNYC_2024_Impact_Report.pdf"}/>
        </div>
    );

    return (
        <div className='top-div'>
            <section className='post-content-section-1' style={postContentSection1}>
                <div className='post-content-section-1-div'>
                    <h2>
                        <div></div>
                        2024 Impact Report
                    </h2>
                </div>
            </section>
            <section className='post-content-section-2'>
                <Sidebar />
                {postContent}
            </section>
        </div>
    );
}

export default ImpactReport;
