import React from 'react';

// @ts-ignore
const PDFViewer = ({ pdfSrc }) => {
    return (
        <div>
            <embed
                src={pdfSrc}
                type="application/pdf"
                width="100%"
                height="800px"
            />
        </div>
    );
};
export default PDFViewer;
